@import "../../assets/style/variables";
@import "../../assets/style/global.scss";

.footer {
  padding: 60px 90px 40px;
  background: $background-color-secondary;

  &_content {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .footer_logo {
      flex: 1;

      img {
        width: 80px;
      }
    }

    &-block {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;

      h6 {
        color: $global-color-secondary;
        font-size: 20px;
        margin-bottom: 30px;
        text-align: center;
        font-family: $font-family-popins;
      }

      .footer-dates {
        display: flex;
        flex-direction: column;
        align-items: center;

        li {
          margin-bottom: 16px;

          a {
            color: $global-color-primary;
            transition: all 0.2s ease;
            white-space: nowrap;
            &:hover {
              color: $global-color-secondary;
            }
          }
        }
      }
    }

    .block_address {
      align-items: flex-end;

      h6 {
        margin-right: 60px;
      }
    }
  }

  &_socials {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;

    &-list {
      display: flex;
      align-items: center;
      column-gap: 20px;

      li {
        display: inline-block;
        a {
          font-size: 25px;

          &:hover {
            i {
              color: $global-color-secondary;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .footer {
    &_content {
      flex-direction: column;
      align-items: center;
      row-gap: 30px;

      .footer_logo {
        img {
          width: 70px;
        }
      }

      &-block {
        h6 {
          margin-bottom: 15px;
        }
      }

      .block_address {
        align-items: center;

        h6 {
          margin-right: 0;
        }
      }
    }
    &_socials {
      margin-top: 45px;
    }
  }
}

@media (max-width: 650px) {
  .footer {
    padding: 40px 30px;
  }
}

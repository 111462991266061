@import "../../../assets/style/variables";

// .background {
//   background: background-color-secondary;
// }

.experience {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  row-gap: 60px;

  padding-top: 120px;

  &_title {
    font-size: 54px;
    font-family: $font-family-popins;
    align-self: center;
  }

  &_container {
    width: 100% !important;
    min-height: 500px;
    display: flex;
    justify-content: space-between;

    &-tab {
      flex: 1;

      .experience_tab-title {
        padding-left: 5px;
        width: 100%;
        color: $global-color-primary;
        font-size: 20px;
        font-family: $font-family-popins;
        transition: all 0.3s ease;
        text-align: left;
        align-items: flex-start;

        // margin-bottom: 10px;

        &:hover {
          color: rgba($color: $global-color-primary, $alpha: 0.5);
        }
      }

      .Mui-selected {
        color: $global-color-secondary !important;
      }

      // .MuiButtonBase-root {
      //   align-items: flex-start;
      // }

      .MuiTabs-indicator {
        background-color: $global-color-secondary;
      }

      .MuiTabs-scroller {
        overflow: visible;
      }

      .MuiTabs-flexContainer {
        border-right: 2px solid rgba($color: $global-color-primary, $alpha: 0.5);
        row-gap: 10px;
      }
    }

    &-content {
      padding-left: 30px;
      flex: 2;

      .companyName {
        a {
          font-size: 32px;
          color: $global-color-secondary;
          font-family: $font-family-popins;
        }

        .role {
          font-size: 24px;
          letter-spacing: 0.1rem;
          font-family: $font-family-popins;
        }
      }

      .description {
        color: $global-color-text;
        font-family: $font-family-popins;
        font-size: 16px;
        margin: 10px auto 0;
      }

      .works {
        margin-top: 20px;
        font-size: 18px;

        li {
          display: flex;
          margin-bottom: 16px;

          i {
            color: $global-color-secondary;
            font-size: 24px;
            margin: 5px 10px 0 0;
          }

          span {
            font-size: 18px;
            line-height: 28px;
            text-align: justify;
          }
        }
      }
    }
  }
}

@media (max-width: 1640px) {
  .experience {
    &_container {
      &-tab {
        .experience_tab-title {
          font-size: 17px;
        }
      }
    }
  }
}
@media (max-width: 1550px) {
  .experience {
    &_title {
      font-size: 48px;
    }

    &_container {
      &-tab {
        .experience_tab-title {
          font-size: 16px;
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .experience {
    &_title {
      font-size: 42px;
    }

    &_container {
      // .MuiTabs-flexContainer {
      //   row-gap: 5px;
      // }

      &-content {
        .companyName {
          a {
            font-size: 24px;
          }

          .role {
            font-size: 18px;
          }
        }
        .description {
          font-size: 14px;
        }

        .works {
          font-size: 16px;

          li {
            span {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .experience {
    &_title {
      font-size: 40px;
    }
  }
}

@media (max-width: 800px) {
  .experience {
    row-gap: 30px;
    padding-top: 60px;
    &_container {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      row-gap: 20px;

      &-content {
        padding-left: 0;

        .companyName-my {
          width: 90%;
          border-top: 2px solid $global-color-secondary;
          padding-top: 25px;
          margin: 0 auto;
        }
        .description-my {
          width: 90%;
          text-align: center;
          margin-top: 10px;
        }
        .works-my {
          width: 100%;
          flex-direction: column;
          margin-top: 20px;
          li {
            width: 90%;
            margin: 0 auto;
            margin-bottom: 16px;
          }
        }
      }

      .role {
        margin-top: 10px;
      }

      .react-reveal {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      &-tab {
        .MuiTabs-flexContainer {
          border: none;
          row-gap: 5px;
        }

        .MuiTabs-indicator {
          display: none;
        }

        .experience_tab-title {
          align-items: center !important;
          justify-content: center;
          text-align: center;
        }
      }

      .experience_tab-header {
        width: 50%;
      }
    }
    &_title {
      font-size: 32px;
    }
  }
}

@media (max-width: 700px) {
  .experience {
    width: 100%;
  }
  .companyName-my {
    width: 90%;
    margin: 0 auto;
    flex-direction: column;
    a {
      font-size: 20px !important;
    }
    span {
      font-size: 16px !important;
    }
  }
  .works-my {
    li {
      span {
        font-size: 14px !important;
        line-height: 20px !important;
      }
    }
  }
}

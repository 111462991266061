@import "../../assets/style/variables";
@import "../../assets/style/global.scss";

.fixed {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background: $background-color-primary;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;

  .hamburger-menu {
    display: none;
  }

  .logo-container {
    .logo {
      width: 60px;
      cursor: pointer;
    }
  }

  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 35px;

    &-link {
      font-size: 18px;
      line-height: 24px;

      a {
        color: $global-color-primary;
        transition: all 0.3s ease;

        &:hover {
          color: $global-color-secondary;
        }
      }
    }
  }
  .resume {
    display: flex;
    justify-content: end;

    a {
      cursor: pointer;
      padding: 8px 15px;
      border-radius: 8px;
      color: $global-color-secondary;
      border: 1px solid $global-color-secondary;
      transition: all 0.3s ease;

      &:hover {
        border-color: $global-color-hover;

        background: rgba($color: #fff, $alpha: 0.1);
      }
    }
  }
}

@media (max-width: 750px) {
  .navbar {
    &__list {
      &-link {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

@media (max-width: 680px) {
  .navbar {
    &__list {
      flex-direction: column;
      justify-content: center;
      row-gap: 25px;

      width: 100%;
      height: 100%;
      position: fixed;

      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      background-color: #3e3e3e;
      z-index: -5;

      &-link {
        font-size: 20px;
      }
    }
    .resume {
      justify-content: center;
    }
    .hamburger-menu {
      display: flex;
      justify-content: end;
      font-size: 30px;
    }
  }
  .display-none {
    display: none !important;
  }
}
